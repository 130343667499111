import './App.css';
import React, {
  useRef,
  useEffect,
  useState
} from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
const data = [require('./data.json'),  require('./data2.json')];

const MAIN_CABIN = [
  -72.732630,
  44.045051,
]
const DAN_CABIN = [
  -72.73473,
  44.045221,
]

mapboxgl.accessToken = 'pk.eyJ1IjoiYmJlcm5heXMiLCJhIjoiY2plNXliam44MGNodTJ5cDh6cWM0bzh1cyJ9.hiD250Z4hyKS72mwKkno6w';

function cleanData() {
  return data[0].map(pt => [pt['@lon'], pt['@lat']])
}

function cleanDataOther() {
  return data[1].map(pt => [pt['@lon'], pt['@lat']]).slice(40, data[1].length)
}

function App() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-72.732193);
  const [lat, setLat] = useState(44.046695);
  const [zoom, setZoom] = useState(15);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y',
      center: [lng, lat],
      zoom: zoom
    });
    map.current.addControl(new mapboxgl.FullscreenControl());
    map.current.on("load", function () {
      map.current.addSource('route', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': cleanDataOther()
          }
        }
      });
      map.current.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#FF0000',
          'line-width': 4
        }
      });
      map.current.addSource('route1', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': cleanData()
          }
        }
      });
      map.current.addLayer({
        'id': 'route1',
        'type': 'line',
        'source': 'route1',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#888',
          'line-width': 4
        }
      });


      new mapboxgl.Marker()
        .setLngLat([
          -72.73473,
          44.045221,
        ]) .addTo(map.current);
      new mapboxgl.Marker()
        .setLngLat([
          -72.732630,
          44.045051,
        ])
        .addTo(map.current);
      map.current.addSource('points', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': MAIN_CABIN
              },
              'properties': {
                'title': 'Main Cabin'
              }
            },{
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': DAN_CABIN
              },
              'properties': {
                'title': 'Dan\'s Cabin'
              }
            },

          ]
        }
      });

      // Add a symbol layer
      map.current.addLayer({
        'id': 'points',
        'type': 'symbol',
        'source': 'points',
        'layout': {
          // 'text-color':['black'],
          'text-field': ['get', 'title'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          'text-offset': [0, 1.25],
          'text-anchor': 'top'
        },
        'paint': {
          "text-color": "#ffffff"
        }
      });
    });

  });

  return (
    <div className="App" >
      <div ref={mapContainer} className="map-container" />
    </div>
  );
}

export default App;